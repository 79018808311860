import React from "react";
import Header from "../../landingPage/Header";
import { Link, Outlet, useLocation } from "react-router-dom";
import Footer from "../../landingPage/Footer";
import { FaGreaterThan } from "react-icons/fa6";

const ParentDashboardLayout = () => {
  const location = useLocation();
  const candidateId = location.pathname.split("/")[2];

  return (
    <div>
      <Header placement="dashboard" dashboard="parent" />
      <div className="parents-dashbaord">
        <div className="banner-back bg-[#001E3B]">
          <div className="container flex justify-between py-5">
            {candidateId ? (
              <div className="left-item text-white">
                <p className="text-lg flex items-center">
                  <Link to="/parentDashboard" className="text-white underline">
                    Dashboard
                  </Link>
                  <span className="mx-2">
                    <FaGreaterThan className="w-4 h-4" />
                  </span>
                  <span className="font-bold">Student Details</span>
                </p>
              </div>
            ) : (
              <div className="left-item text-white space-y-4">
                <h1 className="text-4xl font-bold">Welcome Parents!</h1>
                <p className="text-lg">
                  Take a moment to explore this dashboard and download resources
                  to help your child prepare for the{" "}
                  <strong>PRACTIDO Online Olympiad 2024</strong>.
                </p>
              </div>
            )}

            <div className="right-item">
              <img
                className="banner-cloud"
                src="/img/new-home/cloud-banner-common.png"
                alt="banner"
              />
            </div>
          </div>
        </div>
        <Outlet />
      </div>

      <Footer />
    </div>
  );
};

export default ParentDashboardLayout;
